<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <validation-observer #default="{ invalid }">
      <b-form>
        <b-row>
          <!-- <b-col cols="12" md="4">
            <b-form-group
              label="Mandatsart"
              label-for="Mandatsart"
            >
              <v-select
                v-model="data.Art"
                :disabled="true"
                :options="art"
                :reduce="val => val.value"
                :clearable="false"
                input-id="Art"
              />
            </b-form-group>
          </b-col> -->

          <b-col v-show="data.Art !== 2" cols="12" md="4">
            <b-form-group
              label="Ort Zivilstandsamt Heirat"
              label-for="zivilstandsamt"
            >
              <b-form-input
                v-model="data.Zivilstandsamt"
              />
            </b-form-group>
          </b-col>

          <b-col v-show="data.Art !== 2" cols="12" md="4">
            <b-form-group
              label="Zuständiges Gericht (siehe Korrespondenz)"
              label-for="gericht"
            >
              <b-form-input
                v-model="data.Gericht"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col v-show="data.Art !== 2" cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Heirat"
              rules="required"
            >
              <b-form-group
                label="Heirat"
                label-for="Heirat"
              >
                <flat-pickr
                  v-model="data.Heirat"
                  :config="config"
                  class="form-control"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Marriage...' : 'Heirat...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Trennung"
              :rules="{ required: true, dateGreater: [data.Heirat] }"
            >
              <b-form-group
                label="Trennung"
                label-for="Trennung"
              >
                <flat-pickr
                  v-model="data.Trennung"
                  :config="config"
                  class="form-control"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Separation...' : 'Trennung muss nach Heirat sein!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col v-show="data.MitKind" cols="12" md="4">
            <b-form-group
              :label="$t('anzahl_kinder')"
              label-for="Kinder"
            >
              <vue-slider
                v-model="data.AnzahlKinder"
                :min="data.Art === 2 ? 0 : 0"
                :max="6"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <app-collapse type="shadow" class="mb-1">
          <app-collapse-item ref="collWohnung" id="collWohnung" title="Wohnung">

            <b-row>
              <b-col cols="12" md="4">
                <b-form-group
                  label="Ist die Situation bezüglich Wohnung bereits geregelt?"
                  label-for="wohnungOK"
                >
                  <b-form-checkbox
                    v-model="data.WohnungOK"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="8" v-show="data.WohnungOK">
                <b-form-group
                  label="Konventionstext"
                  label-for="WonungText"
                >
                  <b-form-textarea
                    v-model="data.WohnungText"
                    rows="2"
                    max-rows="2"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-show="!data.WohnungOK">
              <b-col cols="12" md="4">
                <b-form-group
                  :label="'Adresse Wohnung bei ' + $root.VNameFrau"
                  label-for="adresseWohnungFrau"
                >
                  <b-form-checkbox
                    v-model="data.IstAdresseWohnungFrau"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group
                  :label="$root.VNameFrau + ' bleibt in der Wohnung'"
                  label-for="frauBleibtInWohnung"
                >
                  <b-form-checkbox
                    v-model="data.WerWohnungID"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group
                  :label="(data.WerWohnungID ? $root.VNameMann : $root.VNameFrau) + ' verlässt die Wohnung bis'"
                  label-for="Heirat"
                >
                  <flat-pickr
                    v-model="data.WannWohnungVerlassen"
                    :config="config"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row v-show="!data.WohnungOK">
              <b-col cols="12" md="4">
                <b-form-group
                  label="Ist die Wohnung gemietet?"
                  label-for="istMiete"
                >
                  <b-form-checkbox
                    v-model="data.IstMiete"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2" v-show="!data.IstMiete">
                <b-form-group
                  label="Eigentümer"
                  label-for="eigentuemer"
                >
                  <v-select
                    v-model="data.Eigentuemer"
                    :options="eigentuemer"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col cols="12" md="2" v-show="data.Art !== 2 && !data.IstMiete && ((data.WerWohnungID && data.Eigentuemer !== 2) || (!data.WerWohnungID && data.Eigentuemer !== 1))">
                <b-form-group
                  label="Dienstbarkeit bis"
                  label-for="dienstbarkeitBis"
                >
                  <flat-pickr
                    v-model="data.DienstbarkeitBis"
                    :config="config"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" v-show="data.Art !== 2 && !data.IstMiete && ((data.WerWohnungID && data.Eigentuemer !== 2) || (!data.WerWohnungID && data.Eigentuemer !== 1))">
                <b-form-group
                  label="Grundbucheintrag für Konvention"
                  label-for="grundbucheintrag"
                >
                  <b-form-textarea
                    v-model="data.Grundbucheintrag"
                    rows="2"
                    max-rows="3"
                  />
                </b-form-group>
              </b-col> -->

              <b-col cols="12" md="4" v-show="!data.WohnungOK && data.IstMiete">
                <b-form-group
                  label="Wurde Mietvertrag bereits übertragen"
                  label-for="mietVertragUebertragen"
                >
                  <b-form-checkbox
                    v-model="data.IstWohnungUebertragung"
                    class="custom-control-primary"
                    name="check-button"
                    @change="setWohnRegelGericht"
                    switch
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" v-show="!data.WohnungOK && data.IstMiete && !data.IstWohnungUebertragung">
                <b-form-group
                  :label="'Antrag Regelung mit Vermieter durch ' + (data.Art === 2 ? 'KESB' : 'Gericht')"
                  label-for="wohnRegelGericht"
                >
                  <b-form-checkbox
                    v-model="data.WohnRegelGericht"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row v-show="!data.WohnungOK && data.IstMiete && !data.IstWohnungUebertragung">
              <b-col cols="12" md="4">
                <b-form-group
                  label="Datum Mietvertrag"
                  label-for="datummietvertrag"
                >
                  <flat-pickr
                    v-model="data.MietVertragDatum"
                    :config="config"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group
                  label="Vermieter"
                  label-for="vermieter"
                >
                  <b-form-input
                    v-model="data.Vermieter"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </app-collapse-item>
        </app-collapse>

        <b-row v-if="data.Art === 0">
          <b-col cols="12" md="4">
            <b-form-group
              label="Beantragung Gütertrennung bei Gericht"
              label-for="AntragGueterTrennung"
            >
              <b-form-checkbox
                v-model="data.IstGuetertrennung"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="data.IstGuetertrennung" cols="12" md="4">
            <b-form-group
              label="Gütertrennung mit Wirkung ab"
              label-for="GuetertrennungAb"
            >
              <flat-pickr
                v-model="data.WannGuetertrennung"
                :config="config"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="data.Art !== 2">
          <b-col cols="12" md="4">
            <b-form-group
              label="Steuerlich getrennt veranlagt"
              label-for="sepVeranlast"
            >
            <b-form-checkbox
                v-model="data.SteuerGetrennt"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              label="Offene Steuerverbindlichkeiten"
              label-for="offenSteuer"
            >
              <b-form-checkbox
                v-model="data.SteuerOffen"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="data.SteuerOffen" cols="12" md="4">
            <b-form-group
              label="Begleichung offene Steuerverbindklichkeiten"
              label-for="aufteilungSteuern"
            >
              <v-select
                v-model="data.SteuerRegel"
                :options="steuerRegel"
                :reduce="val => val.value"
                :clearable="false"
                input-id="steuerRegel"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="data.Art === 1 && !data.MitVA">
          <b-col cols="12" md="4">
            <b-form-group
              label="Berufliche Vorsorge: Verzicht auf Teilung Vorsorgeguthaben"
              label-for="VAVerzicht"
            >
              <b-form-checkbox
                v-model="data.VAVerzicht"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="!data.VAVerzicht" cols="12" md="4">
            <b-form-group
              label="Berufliche Vorsorge: Hälftige Teilung Vorsorgeguthaben"
              label-for="VATeilungHalb"
            >
              <b-form-checkbox
                v-model="data.VATeilungHalb"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="data.Art !== 2">
          <b-col v-show="!data.MitGA" cols="12" md="4">
            <b-form-group
              label="Güterrechtlich bereits auseinandergesetzt"
              label-for="GAVerzicht"
            >
              <b-form-checkbox
                v-model="data.GAKeine"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="1 !== 1 && data.Art !== 2 && !data.GAKeine">
          <b-col v-show="!data.MitGA" cols="12" md="4">
            <b-form-group
              label="Güterrecht: Liegenschaft vorhanden"
              label-for="mitLiegenschaft"
            >
              <b-form-checkbox
                v-model="data.GAHatLG"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="!data.MitGA && data.GAHatLG && !data.GAAufloesungME" cols="12" md="4">
            <b-form-group
              label="Güterrecht: Liegenschaft in Gesamteigentum"
              label-for="LGinMiteigentum"
            >
              <b-form-checkbox
                v-model="data.GAHatLGME"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="data.GAHatLGME" cols="12" md="4">
            <b-form-group
              label="Güterrecht: Auflösung Liegenschaft im Gesamteigentum"
              label-for="gaAufloesungME"
            >
              <b-form-checkbox
                v-model="data.GAAufloesungME"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col v-show="data.GAHatLG && !data.GAAufloesungME" cols="12" md="4">
            <b-form-group
              label="Güterrecht: Übertrag Liegenschaft"
              label-for="uebetragLG"
            >
              <b-form-checkbox
                v-model="data.GAUebertragLG"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <!-- <b-col v-show="(data.GAHatLG && data.GAUebertragLG && !data.GAHatLGME) || data.GAAufloesungME" cols="12" md="4"> -->
          <b-col v-show="data.GAAufloesungME" cols="12" md="4">
            <b-form-group
              :label="'Güterrecht: Übertrag Liegenschaft auf ' +  $root.VNameFrau"
              label-for="gaAufloesungME"
            >
              <b-form-checkbox
                v-model="data.GAUebertragLGFrau"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="data.Art !== 2 && !data.GAKeine">
          <b-col cols="12" md="4">
            <b-form-group
              label="Güterrecht: 3a und/oder 3b behandeln"
              label-for="ga3a"
            >
              <b-form-checkbox
                v-model="data.GA3a"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group
              label="Güterrecht: Übriges Güterrecht"
              label-for="gaUebriges"
            >
              <b-form-checkbox
                v-model="data.GAUebriges"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

        </b-row>

      </b-form>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="save_changes(true)"
      >
        Speichern
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
// import { required } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import VueSlider from 'vue-slider-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import dos from '@/service/dossier'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    flatPickr,
    vSelect,
    VueSlider,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      data: null,
      art: [],
      eigentuemer: [],
      erziehungsgutschriftGeteilt: true,
      erziehungsgutschriftMutter: false,
      // required,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'Y-m-d', // Internes Format zu Speicherung...
        altInput: true,
        altFormat: 'd.m.Y', // Anzeigeformat...
        allowInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  async mounted() {
    this.$root.$refs.ALLGEMEIN = this
    window.saveFormData = this.save_changes

    await this.fetchData()

    extend('dateGreater', {
      async validate(value, p) {
        if (p[0] <= value) return true
        return 'Datum kleiner als vorheriges Datum!'
      },
    })
  },
  async created() {
    this.art = [
      { label: 'Trennung', value: 0 },
      { label: 'Scheidung', value: 1 },
      { label: 'Elternvereinbarung', value: 2 },
    ]
    this.eigentuemer = [
      { label: 'Gemeinsam', value: 0 },
      { label: this.$root.VNameMann, value: 1 },
      { label: this.$root.VNameFrau, value: 2 },
    ]
    this.steuerRegel = [
      { label: this.$root.VNameMann + ' begleicht offene Steuern', value: 'mann' },
      { label: this.$root.VNameFrau + ' begleicht offene Steuern', value: 'frau' },
      { label: 'hälftige Teilung ' + this.$root.VNameMann + ' und ' + this.$root.VNameFrau, value: 'halb' },
      { label: '%-uale Teilung im Verhältnis Gehälter', value: 'proz' },
    ]
  },
  methods: {
    async fetchData() {
      const r = await dos.getDossier(this.$root.currDosID)
      if (r.data.length > 0) {
        this.data = r.data[0]
        this.data.AnspruchElternID = this.data.AnspruchElternID === 2
        this.data.WerWohnungID = this.data.WerWohnungID === 2 // 1=Mann, 2=Frau
      }
    },
    async save_changes(showToast) {
      this.$root.AnzahlKinder = this.data.AnzahlKinder
      // this.$root.currDosBez = this.currDosArt === 0 ? 'Trennung ' : this.currDosArt === 1 ? 'Scheidung ' : 'Elternvereinbarung ' + this.data.Bezeichnung // eslint-disable-line
      await dos.putDossier(this.data)

      this.$root.$refs.GRUNDDATEN.fetchData()

      if (showToast) this.$root.showToast('Speichern', 'Allgemeine Grunddaten erfolgreich gespeichert!')
    },
    async setWohnRegelGericht() {
      this.data.WohnRegelGericht = !this.data.IstWohnungUebertragung
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
